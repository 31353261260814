.topBanner {
  width: 65vw;
  height: 4.3vw;
  margin: auto;
  background-color: red;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.06);
  border-radius: 1vw;
  padding: 1vw 1.5vw;
  .L {
    display: flex;
    align-items: center;
    img {
      width: 10vw;
      margin-right: 0.5vw;
    }
    font-size: 1.3vw;
    color: #ffffff;

    font-weight: bold;
  }
  .R {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 1vw;
    gap: 4vw;
    .item {
      cursor: pointer;
      position: relative;
    }
    .itemActive {
      font-weight: bold;
      background: linear-gradient(0.000006216200031699429deg, #85ea35 0%, #fed703 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .itemActive::after {
      content: '';
      display: block;
      width: 1vw;
      height: 3px; /* 横线的高度 */
      position: absolute;
      bottom: -6px; /* 定位到底部 */
      left: 50%;
      transform: translate(-50%, 50%);
      border-radius: 20px;
      background: linear-gradient(0.000006216200031699429deg, #85ea35 0%, #fed703 100%);
    }
  }
}
