.App {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #ffffff;
  background-image: url(../assets/BG.png);
  background-size: 100% 100%;
  padding-top: 20px;
  .content {
    background-image: url(../assets/star.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 75vw;
    margin: auto;
    height: 80%;
    margin-top: 88px;
    display: flex;
    justify-content: space-between;
    padding: 0 5vw;
    .L {
      width: 50%;
      margin-top: 180px;
      .play {
        width: 25vw;
        min-height: 7.5vw;
        margin-bottom: 30px;
      }
      .desc {
        font-size: 1vw;
        color: #fff;
        min-height: 16vw;
      }
      .btns {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 25px;
      }
      .btn {
        border: 1px solid #fff;
        width: fit-content;
        padding: 0.8vw 1.2vw;
        border-radius: 1.5vw;
        display: flex;
        gap: 0.5vw;
        zoom: 0.7;
        margin-top: 2.2vw;
        cursor: pointer;
        display: flex;
        align-items: center;
        img {
          height: 3.6vw;
        }
        .right {
          text-align: left;
          color: #fff;
          margin-left: 5px;
          font-size: 1.4vw;
          span {
            font-weight: bold;
            font-size: 2vw;
          }
        }
      }
    }
    .R {
      padding-right: 1vw;

      z-index: 99999;
      .cont {
        position: relative;
        width: 25vw;
        height: 25vw;
        img {
          animation: anim 0.8s ease-in-out;
          object-fit: contain;
        }
        @keyframes anim {
          0% {
            opacity: 0;
            transform: translateX(5%);
          }
          100% {
            opacity: 1;
            transform: translateX(0);
          }
        }
      }
      .indicatorStyle {
        position: absolute;
        bottom: -6vw;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
      }
    }
  }
  .bottom {
    color: #ffffff;
    text-align: center;
    margin-bottom: 20px;
    opacity: 0.6;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
